#applicationPreviewSurvey {
  background-color: #ffffff;
}

#applicationPreviewSurvey .sd-page {
  padding: 0;
}

#applicationPreviewSurvey .sd-element--with-frame:not(.sd-element--collapsed) {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
}

#applicationPreviewSurvey .sd-container-modern {
  margin-bottom: 0;
}

#applicationPreviewSurvey .sd-body.sd-body--static {
  max-width: initial !important;
}

.file-container .sd-file__list {
  position: relative;
}

/* use for loading style of file section */
.file-container .sd-file__list:after {
  position: absolute;
  top: 1%;
  left: 1%;
  bottom: 1%;
  right: 1%;
  background-color: rgba(255, 255, 255, 1);
  z-index: 999;
  content: 'Loading...';
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-container .sjs_sp_placeholder[style*='display: none'] + div {
  position: relative;
}

/* use for loading style of signature area */
.file-container .sjs_sp_placeholder[style*='display: none'] + div:after {
  position: absolute;
  top: 1%;
  left: 1%;
  bottom: 1%;
  right: 1%;
  background-color: rgba(255, 255, 255, 1);
  z-index: 999;
  content: 'Loading...';
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#applicationPreviewSurvey .sd-checkbox--checked.sd-checkbox--disabled .sd-checkbox__svg use {
  /* #161616 with opacity 0.7 */
  fill: rgba(22, 22, 22, 0.7);
}

#applicationPreviewSurvey .sd-item--disabled .sd-item__control-label,
.sd-boolean--checked .sd-boolean__thumb,
.sd-input--disabled {
  opacity: 0.7;
}
