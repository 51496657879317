// Reference to metronic style content

// Auth form
.MuiInput-underline:focus:not(.Mui-focused):before,
.MuiInput-underline:active:not(.Mui-focused):before,
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid #5867dd !important;
}

.MuiFormControl-marginNormal {
  width: 100%;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: #5867dd;

  &:hover {
    background-color: rgba(88, 103, 221, 0.08);
  }

  & + .MuiSwitch-track {
    background-color: #5867dd;
  }
}

// quick-actions-panel
.MuiBackdrop-root {
  background: rgba(0, 0, 0, 0.05) !important;
  left: 0;
}

// Material UI
// For Select component
.react-select {
  .MuiInputBase-input {
    display: flex;

    .css-1okebmr-indicatorSeparator {
      margin: 0;
    }

    & > div:first-child {
      flex: 1;
      .MuiTypography-colorTextSecondary {
        left: 2px;
        bottom: 6px;
        position: absolute;
        font-size: 16px;
      }
      input {
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-weight: 400;
        line-height: 1.5;
        font-size: 16px;
      }
    }
  }
  .MuiInputBase-root {
    font-size: 1.4rem;
  }
}

.MuiPaper-elevation1 {
  box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15) !important;
}
