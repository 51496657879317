/* override surveyJS default css*/
body #previewSurvey {
  /* SurveyJS Creator V2 */
  --primary: #e8192c;
  --sjs-primary-backcolor-dark: #e8192c;
  --primary-light: #fce6eb;
  --background: #ffffff;
  --background-dim: #ffffff;
  --background-dim-light: #f9f9f9;
  --primary-foreground: #ffffff;
  --foreground: #333;
  --red: #e8192c;
  --sd-base-padding: 0;
  --sv-element-add-padding-left: 0;
  --sv-element-add-padding-right: 0;
}

#previewSurvey .sd-body.sd-body--responsive,
.sd-body.sd-body--static {
  padding: 0 !important;
}

#previewSurvey .sd-question__required-text {
  color: #e8192c !important;
}

#previewSurvey .MuiFormControl-root {
  margin-top: 0 !important;
}

#previewSurvey .sd-page,
.svc-logic-question-value {
  padding: 0 !important;
}

#previewSurvey .sd-element--with-frame > .sd-question__erbox--below-question {
  margin-bottom: 0 !important;
  bottom: 0 !important;
}

#previewSurvey :not(.svc-row) > .sd-page__row.sd-row--multiple {
  /* padding-bottom: 2rem !important; */
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
}

#previewSurvey .sd-question__erbox--below-question {
  margin-top: 0px !important;
}

/* pc */
#previewSurvey .sd-element--with-frame {
  padding-bottom: 2rem !important;
  padding-top: 0 !important;
}

/* phone */
@media (max-width: 640px) {
  #previewSurvey .sd-row {
    margin-top: 0.6rem !important;
  }

  /* multiple first question in mobil*/
  #previewSurvey .sd-row--multiple > div {
    margin-bottom: 1rem !important;
  }
}

#previewSurvey .svc-logic-question-value {
  padding: 0 !important;
}

#previewSurvey .label-border:focus-within {
  border: 1px solid #444 !important;
}

#previewSurvey :not(.svc-row) > .sd-page__row.sd-row--multiple {
  padding-right: 0 !important;
}

/* disable question box-shadow */
#previewSurvey .sd-element,
.sd-row--multiple {
  box-shadow: none !important;
}

#previewSurvey .sd-container-modern {
  margin-bottom: 0 !important;
}

#previewSurvey .sd-body.sd-body--static {
  max-width: none !important;
}

#previewSurvey .sd-question__erbox {
  background: transparent !important;
  padding-left: 0 !important;
  margin-left: 0 !important;
  margin-bottom: 0 !important;
  /* set error msg position: at the bottom of question */
  /* position: absolute;
  bottom: 0;
  padding-top: 8px; */
}

/* submit btn */

#previewSurvey .sd-body__navigation {
  padding: 0 !important;
}

#previewSurvey .sd-btn {
  font-size: 1em;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  padding: 8px !important;
}

#sv-nav-complete {
  width: 100%;
}

#previewSurvey input::placeholder {
  font-size: 1rem !important;
}

/* #previewSurvey .region-phone-custom-class {
  padding-bottom: 15px !important;
}

#previewSurvey .region-phone-custom-header-class {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

#previewSurvey .region-phone-custom-header-class::after {
  content: none !important;
} */

#previewSurvey .sd-panel__content {
  padding-top: 0 !important;
}

#previewSurvey .sd-element__header::after {
  content: none !important;
}

#previewSurvey .sd-panel__header {
  padding-bottom: calc(0.5 * var(--sd-base-vertical-padding));
}

#previewSurvey .sd-panel__content .sd-element:not(.sd-element--collapsed) {
  padding-top: 0 !important;
}
