.container {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: #ffffff;
  height: 100%;
}

.header {
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.header:before {
  content: '';
  display: inline-block;
  height: 16px;
  width: 3px;
  background-color: red;
  margin-right: 10px;
  margin-left: 10px;
  border-top-left-radius: 2px;
}

.rowContainer {
  display: flex;
  align-items: center;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.headerRowContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.fieldContainer {
  width: 250px;
  box-sizing: border-box;
}

.header {
  font-size: 1.2rem;
  font-weight: bold;
  padding-bottom: 10px;
}

.field {
  font-size: 1rem;
  margin-right: 10px;
}

.mandatory {
  color: red;
}

.sectionMargin {
  margin-bottom: 15px;
}

.textAreaRowContainer {
  width: 100%;
  display: flex;
}

.textAreaFieldContainer {
  padding-top: 15px;
  min-width: var(--FIELD_CONTAINER_WIDTH);
  box-sizing: border-box;
}

.textArea {
  line-height: 1.5;
  min-height: 40px;
}

.errorText {
  font-size: 9px;
  color: #f018a6;
}

.footerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialogPaper {
  width: 620px;
  height: 600px;
  max-width: 620px;
  padding: 16px;
  background: white;
  /* overflowY: hidden; */
}

.closeBtn {
  float: right;
  cursor: pointer;
}

.leftCheckboxPadding {
  margin-left: -9px;
}