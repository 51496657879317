#event {
  height: calc(100% - 71px);
  --primary: #e8192c;
}

#event .svc-top-bar {
  --primary: #007bff;
}

#event .svc-tab-designer .sd-container-modern .sd-container-modern__title {
  background-color: #e8192c;
}

#event .sd-root-modern:not(.svc-tab-designer) .sd-container-modern__title {
  background-color: #e8192c;
}

#event .sd-header__text h3 {
  color: #fff;
}

#event .sd-header__text .sd-description {
  color: #fff;
}

#event .survey-logo {
  display: none !important;
}

.sd-list__item.sd-list__item {
  min-height: 30px !important;
}

.svc-page__add-new-question .svc-text {
  color: #19b394;
}

.svc-panel__add-new-question {
  display: none !important;
}

.svc-panel__question-type-selector {
  display: none !important;
}

.sv-string-editor::before {
  display: none;
}
#event .sd-btn--action {
  display: none;
}
.svg-icon[aria-label='Open survey settings'] {
  display: none !important;
}

.sv-action-bar-item--secondary .sv-action-bar-item__icon use {
  fill: #ff9814 !important;
}

.sv-action-bar--small-size-mode .sv-action-bar-item {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
}

.svc-question__content-actions {
  inset-inline-end: 0px;
}

#isrequired {
  display: none;
}

/* #delete {
  display: none;
} */
