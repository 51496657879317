/* fixed table header row */
#material-table table th {
  position: sticky;
  top: 0;
  z-index: 99;
  line-height: 1rem;
  padding: 8px;
}

#material-table table thead {
  background-color: #000;
}